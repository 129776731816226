<template>
  <div class="register">
    <div class="container h-100">
      <div class="row h-100">
        <form @submit.prevent="loadingHandler" class="col-xl-4 col-ml-5 col-lg-6 m-auto h-100">
          <div class="card_register">
            <h1>{{ $localize('sign-up')['title'] }}</h1>

            <div class="form_input">
              <label class="form-label" for="inp2">
                {{ $localize('sign-up')['form-label'] }}
              </label>
              <!-- <input v-model="date" v-mask="11 / 11 / 1111" placeholder="Date" /> -->
              <input
                type="tel"
                v-model="number"
                v-mask="'+998 (##) ###-##-##'"
                placeholder="+998"
                ref="inputNumber"
                @input="changeHandler"
                id="inp2"
                class="form-control"
              />
              <small v-if="agree">
                {{ $localize('sign-up')['phone-number'] }}
              </small>
            </div>

            <ul>
              <li>
                <div class="vs-checkbox-con">
                  <input
                    v-model="check"
                    type="checkbox"
                    id="1601"
                    class="vs-checkbox"
                    @change="checked"
                  />
                  <div class="vs-checkbox-mask">
                    <i class="vs-icon-check">
                      <span>
                        <div class="line1"></div>
                        <div class="line2"></div>
                      </span>
                    </i>
                  </div>
                </div>
                <label for="1601">
                  <p
                    style="color: #1E1E1E"
                    v-html="$t('auth.personalData')"
                  >
                  </p>
                </label>
              </li>
              <li v-if="agree">
                <small></small>
              </li>
            </ul>
            <button
              :disabled="loading"
              type="submit"
              :class="{ 'btn-loading': loading }"
              class="p-button"
              :data-disabled="!check || !validIsMin"
            >
              {{ $localize('button')['continue'] }}
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
// import message from '../../utils/messages.js';

export default {
  name: 'Register',
  data() {
    return {
      loading: false,
      number: '+998',
      check: false,
      agree: false,
      fetchNumber: '',
    };
  },
  computed: {
    ...mapGetters({ userStatus: 'buyers/userStatus', passportType: 'buyers/passportType' }),
    validIsMin() {
      return this.number.length > 18;
    },
  },
  methods: {
    checked() {
      console.log(this.check);
    },
    changeHandler() {
      if (this.number.length === 13) {
        this.agree = false;
      }
    },
    loadingHandler(e) {
      if (this.check && this.validIsMin) {
        this.loading = true;
        this.$toasted.clear();
        setTimeout(() => {
          let phoneNumber = this.number.replace(/[^0-9]/g, '');
          this.$axios
            .post('login/send-sms-code?phone=' + phoneNumber)
            .then((res) => {
              if (res.data.status === 'success') {
                localStorage.setItem('number', this.number);
                localStorage.setItem('check', this.check);
                localStorage.setItem('hash', res.data.hash);

                this.$toasted.clear();
                this.loading = false;

                this.$router.push({
                  name: 'phone-number-confirm',
                  query: {
                    phone: phoneNumber,
                    redirect: this.$route.query.redirectTo,
                    contractId: this.$route.query.contractId,
                  },
                });
              } else {
                // remove spaces and add underscore
                if (res.data.info.includes(' ')) {
                  const changedMessage = res.data.info.replace(/\s+/g, '_').toLowerCase();
                  this.$toastError(this.$localize('error')[changedMessage]);
                }
                this.$toastError(this.$localize('error')[res.data.info]);
                this.loading = false;
              }

            })
            .catch((e) => {
              this.loading = false;
              console.error(e.message);
            });
        }, 2000);
      } else {
        e.preventDefault();
      }
    },
    redirectOnlyVerifiedUser () {
      if (this.userStatus === 4) {
        this.$router.push({ name: 'profile-index' })
      } else {
        this.$router.push({ name: 'auth-failed' })
      }
    }
  },
  mounted() {
    this.$store.dispatch('buyers/userStatus');
    const api_token = this.$cookieGet('token');

    // заглушка: PAYMART-1058
    if (api_token) {
      this.redirectOnlyVerifiedUser()
    }

    // if (api_token) {
    //   if (this.passportType === 6 && this.userStatus) {
    //     switch (this.userStatus) {
    //       case 0:
    //         this.$router.push({ name: 'auth' });
    //         break;
    //       case 1:
    //         this.$router.push({ name: 'cardAdd' });
    //         break;
    //       case 2 || 6:
    //         this.$router.push({ name: 'loading' });
    //         break;
    //       case 4:
    //         this.$router.push({ name: 'profile-index' });
    //         break;
    //       case 5:
    //         this.$router.push({ name: 'upload-passport' });
    //         break;
    //       case 8:
    //         this.$router.push({ name: 'refusal' });
    //         break;
    //       case 10:
    //         this.$router.push({ name: 'upload-selfie-passport' });
    //         break;
    //       case 11:
    //         this.$router.push({ name: 'upload-address-passport' });
    //         break;
    //       case 12:
    //         this.$router.push({ name: 'confidant' });
    //         break;
    //       default:
    //         this.$router.push({ name: 'home' });
    //         break;
    //     }
    //     // this.$router.push({ name: 'profile' });
    //   }
    //   else if (this.passportType === 0  && this.userStatus) {
    //     switch (this.userStatus) {
    //       case 0:
    //           this.$router.push({ name: 'auth' });
    //         break;
    //       case 1:
    //           this.$router.push({ name: 'cardAdd' });
    //         break;
    //       case 2 || 6:
    //         this.$router.push({ name: 'loading' });
    //         break;
    //       case 4:
    //           this.$router.push({ name: 'profile-index' });
    //         break;
    //       case 5:
    //           this.$router.push({ name: 'upload-id-card' });
    //         break;
    //       case 8:
    //           this.$router.push({ name: 'refusal' });
    //         break;
    //       case 10:
    //           this.$router.push({ name: 'upload-selfie-id-card' });
    //         break;
    //       case 11:
    //           this.$router.push({ name: 'upload-address-id-card' });
    //         break;
    //       case 12:
    //           this.$router.push({ name: 'confidant-id-card' });
    //         break;
    //       default:
    //         this.$forceUpdate();
    //         break;
    //     }
    //   }
    // }
    //
    // if (this.$route.query.error) {
    //   this.$toasted.error(message[this.$route.query.error], {
    //     className: 'error-toasted',
    //     action: {
    //       text: this.$localize('button')['cancel'],
    //       class: 'action-toasted',
    //       onClick: (e, toastObject) => {
    //         toastObject.goAway(0);
    //       },
    //     },
    //   });
    // }
    // if (localStorage.getItem('number')) {
    //   this.number = localStorage.getItem('number');
    // }
    // if (localStorage.getItem('check')) {
    //   this.check = localStorage.getItem('check');
    // }
  },
};
</script>

<style lang="scss" scoped>
  .register {
    background: $grey;
    height: calc(100vh - 100px);

    .card_register {
      padding-top: 112px;
      /*position: relative;*/

      h1 {
        font-weight: 900;
        font-size: 40px;
        line-height: 40px;
        text-align: center;
        color: $black;
        margin-bottom: 32px;
      }

      ul {
        li {
          margin-bottom: 16px;
          display: flex;
          align-items: flex-start;

          .vs-checkbox-con {
            min-width: 23px;
            height: 23px;
            border-radius: 9px;
            position: relative;
            z-index: 1;

            .vs-checkbox {
              position: absolute;
              width: 100%;
              height: 100%;
              top: 0;
              left: 0;
              margin: 0;
              padding: 0;
              opacity: 0;
              z-index: 100;
              cursor: pointer;
            }

            .vs-checkbox:checked ~ .vs-checkbox-mask {
              border: none;
            }

            .vs-checkbox:checked ~ .vs-checkbox-mask:before {
              opacity: 0;
              -webkit-transform: scale(1.2);
              transform: scale(1.2);
            }

            .vs-checkbox:checked ~ .vs-checkbox-mask:after {
              opacity: 1;
              -webkit-transform: scale(1);
              transform: scale(1);
            }

            .vs-checkbox:checked ~ .vs-checkbox-mask .vs-icon-check {
              opacity: 1;
            }

            .vs-checkbox:checked
            ~ .vs-checkbox-mask
            .vs-icon-check
            span
            .line1:after {
              width: 100%;
              -webkit-transition: all 0.25s ease 0.1s;
              transition: all 0.25s ease 0.1s;
            }

            .vs-checkbox:checked
            ~ .vs-checkbox-mask
            .vs-icon-check
            span
            .line2:after {
              -webkit-transition: all 0.2s ease 0.3s;
              transition: all 0.2s ease 0.3s;
              height: 100%;
            }

            .vs-checkbox-mask .vs-icon-check {
              opacity: 0;
              z-index: 200;
            }

            .vs-icon-check {
              width: 23px;
              height: 23px;
              display: -webkit-box;
              display: -ms-flexbox;
              display: flex;
              -webkit-box-align: center;
              -ms-flex-align: center;
              align-items: center;
              -webkit-box-pack: center;
              -ms-flex-pack: center;
              justify-content: center;
              -webkit-transition: all 0.25s ease;
              transition: all 0.25s ease;
              border-radius: inherit;

              span {
                -webkit-transform: rotate(45deg);
                transform: rotate(45deg);
                display: block;
                position: relative;
                width: 8px;
                height: 13px;
                margin-top: -4px;

                .line1 {
                  background: transparent;
                  content: '';
                  position: absolute;
                  height: 2px;
                  border-radius: 2px;
                  -webkit-transition: all 0.2s ease;
                  transition: all 0.2s ease;
                  border-radius: 5px;
                  z-index: 100;
                  width: 8px;
                  bottom: 0;

                  &::after {
                    content: '';
                    position: absolute;
                    width: 0;
                    height: 2px;
                    background: $white;
                    -webkit-transition: all 0.25s ease;
                    transition: all 0.25s ease;
                    border-radius: 5px 0 0 5px;
                  }
                }

                .line2 {
                  bottom: 0;
                  right: 0;
                  z-index: 100;
                  background: transparent;
                  content: '';
                  position: absolute;
                  height: 13px;
                  border-radius: 5px;
                  -webkit-transition: all 0.2s ease;
                  transition: all 0.2s ease;
                  width: 2px;

                  &::after {
                    content: '';
                    position: absolute;
                    width: 2px;
                    height: 0%;
                    background: $white;
                    -webkit-transition: all 0.25s ease;
                    transition: all 0.25s ease;
                    bottom: 0;
                    border-radius: 5px 5px 0 0;
                  }
                }
              }
            }

            .vs-checkbox-mask {
              border: 1px solid #333333;
              border-radius: 32%;
              width: 100%;
              height: 100%;
              position: absolute;
              z-index: 50;
              cursor: pointer;
              pointer-events: none;
              -webkit-box-sizing: border-box;
              -webkit-transition: all 0.25s ease;
              transition: all 0.25s ease;
              display: -webkit-box;
              display: -ms-flexbox;
              display: flex;
              -webkit-box-align: center;
              -ms-flex-align: center;
              align-items: center;
              -webkit-box-pack: center;
              -ms-flex-pack: center;
              justify-content: center;
              z-index: -1;
              box-sizing: border-box;

              &::before,
              &::after {
                content: '';
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                z-index: -1;
              }

              &::before {
                border-radius: inherit;
                -webkit-transition: all 0.25s ease;
                transition: all 0.25s ease;
                border: 2px solid rgba(var(--vs-gray-4), 1);
                -webkit-box-sizing: border-box;
                box-sizing: border-box;
              }

              &::after {
                background: $main;
                border-radius: inherit;
                transition: all 0.25s ease;
              }
            }

            .vs-checkbox-mask::after {
              opacity: 0;
              -webkit-transition: all 0.25s ease;
              transition: all 0.25s ease;
              -webkit-transform: scale(0.5);
              transform: scale(0.5);
            }
          }

          .icon {
            padding: 4px;
            background: $main;
            border-radius: 8px;
            display: flex;
            align-items: center;
          }

          p {
            font-size: 16px;
            line-height: 24px;
            letter-spacing: 0.01em;
            color: $black;
            margin-left: 8px;
          }

          label {
            font-size: 16px;
            line-height: 24px;
            letter-spacing: 0.01em;
            color: $black;
            margin-left: 8px;
          }
        }
      }
    }
  }

  button.p-button {
    cursor: pointer;
    background: $main;
    box-shadow: -12px 12px 30px rgba(102, 16, 245, 0.25);
    border-radius: 14px;
    width: 100%;
    padding: 18px 24px;
    font-weight: 600;
    font-size: 18px;
    line-height: 111.63%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: $white;
    transition: 0.2s;
    border: none;

    &:hover {
      background: $main-light;
    }

    &[data-disabled] {
      background: #d8d8d8;
      color: $white;
      cursor: not-allowed;
      box-shadow: none !important;

      &:hover {
        background: #d8d8d8;
        color: $white;
        cursor: not-allowed;
        box-shadow: none !important;
      }
    }
  }

  .btn-loading {
    position: relative;
    pointer-events: none;
    color: transparent !important;

    &:after {
      animation: spinAround 500ms infinite linear;
      border: 2px solid $white;
      border-radius: 50%;
      border-right-color: transparent;
      border-top-color: transparent;
      content: '';
      display: block;
      height: 1em;
      width: 1em;
      position: absolute;
      left: calc(50% - (1em / 2));
      top: calc(50% - (1em / 2));
    }
  }

  @keyframes spinAround {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(359deg);
    }
  }

  .m-auto {
    margin: auto;
  }

  .form_input {
    margin-bottom: 32px;
    width: 100%;

    .form-label {
      margin-bottom: 4px;
      font-size: 16px;
      line-height: 24px;
      letter-spacing: 0.01em;
      color: $black;
    }

    .form-control {
      width: 100%;
      padding: 16px;
      font-size: 16px;
      line-height: 24px;
      letter-spacing: 0.01em;
      color: $black;
      box-sizing: border-box;
      background: $white;
      border-radius: 8px;
      border: none;
      transition: 0.4s;

      &::placeholder {
        font-size: 16px;
        line-height: 24px;
        letter-spacing: 0.01em;
        color: rgba(30, 30, 30, 0.3);
      }

      &:focus {
        border: 1px solid #6610F5;
        outline: none;
        color: rgb(30, 30, 30);
      }
    }

    small {
      color: red;
    }
  }

  .d-none-sm {
    display: block;
  }

  .mobile-view {
    display: flex !important;
    align-items: center !important;

    p {
      font-size: 13px;
      line-height: 16px;
      letter-spacing: -0.078px;
      color: $main !important;
    }
  }

  @media (max-width: 575px) {
    .d-none-sm {
      display: none !important;
    }

    .h-100 {
      height: 100%;
    }
    .register {
      height: calc(100vh - 48px);

      .form_input .form-label {
        font-size: 16px;
        line-height: 24px;
        letter-spacing: 0.01em;
        color: $black;
      }

      .card_register {
        padding-top: 48px;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        padding-bottom: 100px;

        h1 {
          font-size: 24px;
          line-height: 40px;
          color: $black;
        }

        ul li {
          align-items: center !important;
        }

        ul li p,
        ul li label {
          font-weight: normal;
          font-size: 14px;
          line-height: 16px;
          letter-spacing: -0.078px;
          color: #333333;
        }

        .form_input .form-control {
          padding: 14px 16px;
          font-size: 14px;
          line-height: 20px;

          &::placeholder {
            font-size: 16px;
            line-height: 20px;
          }
        }

        button {
          /*height: auto;*/
          /*margin-top: 48px;*/
          margin-bottom: 32px;
          font-size: 15px;
          padding: 16px 24px;
          margin-top: auto;
        }
      }
    }
  }
</style>
